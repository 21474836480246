<template>
  <v-footer app padless>
    <v-row justify="center" no-gutters dense>
      <v-col :class="textClass">
        {{ footerText }}
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
// design
import {
  alignments,
  displayTypes,
  fontEmphasis,
  opacities,
  Text
} from "@/design/text/Text";

// services
import { version, appName, appYear } from "@/services/config/configService";
import { publicAccessMixin } from "@/mixins/public/publicAccessMixin";

export default {
  name: "AppFooter",
  mixins: [publicAccessMixin],
  computed: {
    footerText() {
      return `${appYear} Version ${version} - ${appName}`;
    },
    textClass() {
      const text = new Text(
        undefined,
        displayTypes.subtitle1,
        fontEmphasis.regular
      );

      text.opacity = opacities.disabled;
      text.alignment = alignments.center;

      return text.getClassText();
    }
  }
};
</script>
